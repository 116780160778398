
module.exports = (function(){
  var d = _eai_d;
  var r = _eai_r;
  window.emberAutoImportDynamic = function(specifier) {
    if (arguments.length === 1) {
      return r('_eai_dyn_' + specifier);
    } else {
      return r('_eai_dynt_' + specifier)(Array.prototype.slice.call(arguments, 1))
    }
  };
  window.emberAutoImportSync = function(specifier) {
    return r('_eai_sync_' + specifier)(Array.prototype.slice.call(arguments, 1))
  };
    d('@discourse/itsatrap', [], function() { return require('@discourse/itsatrap'); });
    d('@popperjs/core', [], function() { return require('@popperjs/core'); });
    d('@uppy/aws-s3', [], function() { return require('@uppy/aws-s3'); });
    d('@uppy/aws-s3-multipart', [], function() { return require('@uppy/aws-s3-multipart'); });
    d('@uppy/core', [], function() { return require('@uppy/core'); });
    d('@uppy/drop-target', [], function() { return require('@uppy/drop-target'); });
    d('@uppy/utils/lib/AbortController', [], function() { return require('@uppy/utils/lib/AbortController'); });
    d('@uppy/utils/lib/delay', [], function() { return require('@uppy/utils/lib/delay'); });
    d('@uppy/utils/lib/EventTracker', [], function() { return require('@uppy/utils/lib/EventTracker'); });
    d('@uppy/xhr-upload', [], function() { return require('@uppy/xhr-upload'); });
    d('handlebars', [], function() { return require('handlebars'); });
    d('message-bus-client', [], function() { return require('message-bus-client'); });
    d('tippy.js', [], function() { return require('tippy.js'); });
    d('virtual-dom', [], function() { return require('virtual-dom'); });
    d('xss', [], function() { return require('xss'); });
})();
